import {
	EmailShareButton,
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton
} from "next-share";

import { copyToClipboard } from "_common/utils/clientSide";
import Icon                from "_components/atoms/icon";
import Typography          from "_components/atoms/typography";

import useStyles from "./styles";

const component = ( { handleClose, shareLink, title, onClickShareItem } ) => {
	const { classes } = useStyles ( undefined, { name: "styles" } );

	return (
		<div>
			<div className = { classes.header }>
				<Typography className = { classes.headerText }
					title = { "Share Via" }
				/>

				<Icon
					className = { classes.closeIcon }
					name = "cross"
					onClick = { handleClose }
				/>

			</div>

			<div className = { classes.socialMediaWrapper }>
				<button
					className = { `${ classes.socialMediaButtons } ${ classes.copyLinkIcon }` }
					onClick = { onClickShareItem }
				>
					<img className = { classes.socialMediaIcons }
						onClick = { () => copyToClipboard ( shareLink ) }
						src = "/images/shareDialogue/copy.png"
					/>

					<Typography className = { classes.iconNames }
						title = { "Copy URL" }
					/>
				</button>

				<WhatsappShareButton
					blankTarget = { true }
					className = { classes.socialMediaButtons }
					onClick = { onClickShareItem }
					separator = { "\n" }
					title = { title }
					url = { shareLink }
				>

					<img className = { classes.socialMediaIcons }
						src = "/images/shareDialogue/whatsapp.png"
					/>

					<Typography
						className = { classes.iconNames }
						title = { "Whatsapp" }
					/>
				</WhatsappShareButton>

				<FacebookShareButton
					blankTarget = { true }
					className = { classes.socialMediaButtons }
					onClick = { onClickShareItem }
					quote = { title }
					url = { shareLink }
				>

					<img className = { classes.socialMediaIcons }
						src = "/images/shareDialogue/facebook.png"
					/>

					<Typography
						className = { classes.iconNames }
						title = { "Facebook" }
					/>

				</FacebookShareButton>

				<TwitterShareButton
					blankTarget = { true }
					className = { classes.socialMediaButtons }
					onClick = { onClickShareItem }
					title = { title }
					url = { shareLink }
				>

					<img className = { classes.socialMediaIcons }
						src = "/images/shareDialogue/twitter.png"
					/>

					<Typography
						className = { classes.iconNames }
						title = { "Twitter" }
					/>

				</TwitterShareButton>

				<EmailShareButton
					blankTarget = { true }
					body = { title }
					className = { classes.socialMediaButtons }
					onClick = { onClickShareItem }
					subject = { title }
					url = { `\n${ shareLink } ` }
				>

					<img className = { classes.socialMediaIcons }
						src = "/images/shareDialogue/mail.png"
					/>

					<Typography
						className = { classes.iconNames }
						title = { "Email" }
					/>
				</EmailShareButton>
			</div>
		</div>
	);
};

export default component;
