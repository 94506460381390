import { Modal } from "@mantine/core";

import { useGetDeviceType } from "_common/hooks/global";
import MobileBottomDrawer   from "_components/atoms/mobileBottomDrawer";

import ShareMainContent from "../shareMainContent";

import useStyles from "./styles";

const ShareModal = ( { handleClose, shareLink, title, open, onClickShareItem } ) => {

	const { classes } = useStyles ( undefined, { name: "styles" } );

	const deviceType = useGetDeviceType ();
	const isMobile   = deviceType === "mobile";

	if ( isMobile ) {
		return (
			<MobileBottomDrawer
				isOpen = { open }
				onClose = { handleClose }
			>
				<ShareMainContent
					handleClose      = { handleClose }
					onClickShareItem = { handleClose }
					shareLink        = { shareLink }
					title            = { title }
				/>
			</MobileBottomDrawer>
		);
	}

	return (
		<Modal
			centered
			classNames = { {
				root    : classes.dialogPaper,
				body    : classes.modalBody,
				content : classes.modalContent
			} }
			onClose = { handleClose }
			opened    = { open }
			radius = { "lg" }
			withCloseButton = { false }
			zIndex = { 1200 }
		>
			<ShareMainContent
				handleClose = { handleClose }
				onClickShareItem = { onClickShareItem }
				shareLink   = { shareLink }
				title       = { title }
			/>
		</Modal>
	);
};

export default ShareModal;
